import styled from 'styled-components'
import {
  defaultColorPalette,
  defaultTransitionTiming,
  MediaMaxS,
  MediaMaxXs,
  shimmerBackground,
} from '@/5_shared/styles'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;

  border-radius: 16px;
  cursor: pointer;
  transition: all ${defaultTransitionTiming.fastest}s ease-in-out;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.2);
  }
`

export const ImageBlock = styled.div`
  width: 100%;
  height: 202px;
  background-color: ${defaultColorPalette.darkTypeMedium};
`

export const Image = styled.img.attrs({})`
  width: 100%;
  // max-width: 216px;
  // max-height: 133px;
  height: 202px;
  border-radius: 16px;
`

export const ImageLoading = styled.div`
  width: 100%;
  height: 202px;
  ${shimmerBackground()}
`

export const InfoBlock = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px;
  gap: 16px;
  background-color: ${defaultColorPalette.darkSurfaceTertiary};
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: ${defaultColorPalette.white};
  text-overflow: ellipsis;
`

export const TitleLoading = styled.div`
  height: 28px;
  width: 100px;
  border-radius: 4px;
  ${shimmerBackground(defaultColorPalette.lightPrimary300)}
`

export const Badges = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  ${MediaMaxS`
    flex-direction: column;
    align-items: flex-start;
  `}
`

export const Badge = styled.div`
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background-color: ${defaultColorPalette.darkPrimary1000};
  color: ${defaultColorPalette.darkPrimary200};
`

export const BadgeLoading = styled.div`
  height: 24px;
  width: 50px;
  border-radius: 4px;

  ${shimmerBackground(
    defaultColorPalette.darkPrimary700,
    defaultColorPalette.darkPrimary900
  )}
`
