import React, { FC, memo, useEffect, useRef } from 'react'
import * as Styled from './styled'
import { useStore } from 'effector-react'
import {
  $isDropdownOpened,
  $readableSelectedLanguage,
  $selectedLanguage,
  closeDropdown,
  dropdownClick,
  selectLanguage,
} from '../model'
import { availableLanguages } from '../model/data'
import { Languages } from '@/5_shared/localized-message'
import { useOutsideClick } from '@/5_shared/hooks'
import { useTranslation } from 'react-i18next'
import { LocalStorageService } from '@/5_shared/local-storage'

interface LanguageSelectionProps {}

const onDropdownClick = () => {
  dropdownClick()
}

export const LanguageSelection: FC<LanguageSelectionProps> = memo(
  ({}: LanguageSelectionProps) => {
    const thisRef = useRef<HTMLDivElement>(null)
    const selectedLanguage = useStore($selectedLanguage)
    const readableSelectedLanguage = useStore($readableSelectedLanguage)
    const isDropdownOpened = useStore($isDropdownOpened)
    console
      .log
      // LocalStorageService.getItem('i18nextLng'),
      // typeof LocalStorageService.getItem('i18nextLng')
      ()
    useEffect(() => {
      // selectLanguage(
      //   String(LocalStorageService.getItem('i18nextLng')) || Languages.English
      // )
    }, [])

    useOutsideClick(thisRef, isDropdownOpened, closeDropdown)
    const { i18n } = useTranslation()
    const onLanguageSelect = (selected: string) => {
      selectLanguage(selected)
      i18n.changeLanguage(selected)
    }

    return (
      <Styled.Root ref={thisRef}>
        <Styled.ImageWrapper onClick={onDropdownClick}>
          <Styled.GlobeIcon />
        </Styled.ImageWrapper>

        <Styled.SelectedLanguage onClick={onDropdownClick}>
          {readableSelectedLanguage}
        </Styled.SelectedLanguage>

        {/* todo to shared */}
        <Styled.Dropdown $isShown={isDropdownOpened}>
          {availableLanguages.map((lang) => (
            <Styled.Option
              key={lang.value}
              onClick={() => onLanguageSelect(lang.value)}
              $isSelected={selectedLanguage === lang.value}
            >
              {lang.label}
            </Styled.Option>
          ))}
        </Styled.Dropdown>

        <Styled.IconWrapper onClick={onDropdownClick}>
          {isDropdownOpened ? (
            <Styled.ChevronUpIcon />
          ) : (
            <Styled.ChevronDownIcon />
          )}
        </Styled.IconWrapper>
      </Styled.Root>
    )
  }
)

LanguageSelection.displayName = 'LanguageSelection'
